.about-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  min-height: 100vh;
  color: #eaeaea;
  font-family: Arial, sans-serif;
  text-align: center;

  background-color: #0f0118; /* Dark Purple background */
  background-image:
    linear-gradient(0deg, rgba(4, 0, 7, 0.8) 1px, transparent 1px),  /* Dark Purple grid lines with opacity */
    linear-gradient(90deg, rgba(31, 3, 50, 0.8) 1px, transparent 1px),  /* Dark Purple grid lines with opacity */
    radial-gradient(circle at 50% 0%, rgba(9, 0, 6, 0.3), transparent 50%),  /* Dark shine at the top center */
    radial-gradient(circle at 30% 80%, rgba(20, 1, 15, 0.6), transparent 50%),  /* Dark shine effect */
    radial-gradient(circle at 70% 80%, rgba(26, 1, 17, 0.6), transparent 50%);  /* Dark shine effect */
  background-size: 40px 40px, 40px 40px, cover, cover, cover;
  background-blend-mode: overlay, overlay, normal, normal, normal;
}



.about-text {
  flex: 1;
  padding-right: 20px;
}

.about-container h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #fff;
}

.about-container p {
  font-size: 1.1em;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto 30px;
  color: #ddd;
}


.skills-button {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #300af0;
  color: white;
  text-decoration: none;
  font-size: 1em;
  font-weight: bold;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.skills-button:hover {
  background-color: #5c5cab;
  transform: translateY(-2px);
}





@media (max-width: 768px) {
  .about-container {
    flex-direction: column; 
    text-align: center;
    
  }


  .about-text {
    padding-right: 0;
    margin-top: 60%;
  }

  .about-container h1 {
    font-size: 2em;
    
  }

  .about-container p {
    font-size: 1em;
    padding: 0 15px;
    
  }

  .skills-button {
    font-size: 0.9em;
    padding: 8px 16px;
  }


 
}


@media (max-width: 480px) {
  .about-container {
    flex-direction: column;
    padding: 15px;
  }

  

  .about-container h1 {
    font-size: 1.8em;
  }

  .about-container p {
    font-size: 0.95em;
    line-height: 1.5;
    padding: 0 10px;
  }

  .skills-button {
    font-size: 0.85em;
    padding: 8px 14px;
  }
}


@media (max-width: 320px) {
  .about-container h1 {
    font-size: 1.5em;
  }

  .about-container p {
    font-size: 0.85em;
    line-height: 1.4;
    padding: 0 5px;
  }

  .skills-button {
    font-size: 0.8em;
    padding: 6px 12px;
  }
}



.certificate-page {
  text-align: center;
  padding: 20px;

  
  background-color: #0f0118; /* Dark Purple background */
  background-image:
    linear-gradient(0deg, rgba(4, 0, 7, 0.8) 1px, transparent 1px),  /* Dark Purple grid lines with opacity */
    linear-gradient(90deg, rgba(31, 3, 50, 0.8) 1px, transparent 1px),  /* Dark Purple grid lines with opacity */
    radial-gradient(circle at 50% 0%, rgba(9, 0, 6, 0.3), transparent 50%),  /* Dark shine at the top center */
    radial-gradient(circle at 30% 80%, rgba(20, 1, 15, 0.6), transparent 50%),  /* Dark shine effect */
    radial-gradient(circle at 70% 80%, rgba(26, 1, 17, 0.6), transparent 50%);  /* Dark shine effect */
  background-size: 40px 40px, 40px 40px, cover, cover, cover;
  background-blend-mode: overlay, overlay, normal, normal, normal;
}


.certificate-page h1 {
  font-size: 2.5em;
  color:whitesmoke;
}

.certificate-section {
  margin-top: 40px;
}

.certificate-section h2 {
  font-size: 1.8em;
  color: goldenrod;
  margin-bottom: 20px;
}

.certificate-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  justify-items: center;
}

.certificate-block {
  background-color: #0f0118; /* Dark Purple background */
  
  border-radius: 10px;
 
  padding: 10px;
  width: 100%;
  max-width: 250px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
}

.certificate-block:hover {
  transform: scale(1.05);
  box-shadow: 0px 6px 12px rgba(125, 244, 143, 0.3);
}

.certificate-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 15px;
  transition: transform 0.3s ease;
}
.certificate-title {
  font-size: 1.2em;
  color: #f5f2f2;
  margin: 10px 0;
}

.certificate-block:hover .certificate-image {
  transform: scale(1.1);
}

.certificate-description {
  font-size: 1em;
  color: #efeaea;
  margin: 10px 0;
}

.certificate-details {
  font-size: 0.9em;
  color: #f0eaea;
}

.certificate-date {
  display: block;
  font-weight: bold;
  color: #ff6b6b;
}

.certificate-issuer {
  display: block;
  font-style: italic;
  color: #02b62f;
}




@media (max-width: 768px) {
  .certificate-page h1 {
    font-size: 2em;
  }

  .certificate-section h2 {
    font-size: 1.6em;
  }

  .certificate-container {
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    gap: 15px;
  }

  .certificate-block {
    max-width: 200px;
    padding: 15px;
  }

  .certificate-description {
    font-size: 0.95em;
  }

  .certificate-details {
    font-size: 0.85em;
  }
  .certificate-block:hover .certificate-image {
    transform: scale(1.1);
  }
}


@media (max-width: 480px) {
  .certificate-page h1 {
    font-size: 1.8em;
  }

  .certificate-section h2 {
    font-size: 1.4em;
    margin-bottom: 15px;
  }

  .certificate-container {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .certificate-block {
    max-width: 100%;
    padding: 10px;
  }

  .certificate-image {
    width: 90%;
    margin: 0 auto 10px;
  }

  .certificate-description {
    font-size: 0.9em;
  }

  .certificate-details {
    font-size: 0.8em;
  }

  .certificate-date,
  .certificate-issuer {
    font-size: 0.85em;
  }
  .certificate-block:hover .certificate-image {
    transform: scale(1.1);
  }
}


@media (max-width: 320px) {
  .certificate-page h1 {
    font-size: 1.5em;
  }

  .certificate-section h2 {
    font-size: 1.2em;
  }

  .certificate-block {
    padding: 8px;
  }

  .certificate-description {
    font-size: 0.85em;
  }

  .certificate-details {
    font-size: 0.75em;
  }

  .certificate-date,
  .certificate-issuer {
    font-size: 0.8em;
  }
  .certificate-block:hover .certificate-image {
    transform: scale(1.1);
  }
}



.projects-page {
    text-align: center;
    padding-top: 10px;
    width: 100vw;
    min-height: 100vh;
    background-color: #0f0118; /* Dark Purple background */
    background-image:
      linear-gradient(0deg, rgba(4, 0, 7, 0.8) 1px, transparent 1px),  /* Dark Purple grid lines with opacity */
      linear-gradient(90deg, rgba(31, 3, 50, 0.8) 1px, transparent 1px),  /* Dark Purple grid lines with opacity */
      radial-gradient(circle at 50% 0%, rgba(9, 0, 6, 0.3), transparent 50%),  /* Dark shine at the top center */
      radial-gradient(circle at 30% 80%, rgba(20, 1, 15, 0.6), transparent 50%),  /* Dark shine effect */
      radial-gradient(circle at 70% 80%, rgba(26, 1, 17, 0.6), transparent 50%);  /* Dark shine effect */
    background-size: 40px 40px, 40px 40px, cover, cover, cover;
  background-blend-mode: overlay, overlay, normal, normal, normal;
  }
  
  .projects-page h1 {
    font-size: 2em;
    margin-bottom: 20px;
    color: #ffffff;
  }
  
  .projects-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); 
    gap: 40px;
    padding: 20px;
    max-width: 1000px;
    margin: 0 auto;
  }
  
  .project-block {
    background-color: #060308; /* Dark Purple background */
   
    border-radius: 8px;
    overflow: hidden;

    transition: transform 0.3s;
  }
  
  .project-block:hover {
    transform: translateY(1px); 
    
  }
  
  .project-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .project-title {
    font-size: 1.2em;
    margin: 10px 0;
    color: #e6e1e1;
  }
  
  .project-description {
    font-size: 1em;
    color: #f7f3f3;
    padding: 0 10px 20px;
  }
  
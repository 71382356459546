

.footer {
  background-color:#121212;
      
      box-shadow: 0 0 15px rgba(236, 238, 236, 0.6), 
                  0 0 30px rgba(42, 44, 42, 0.4), 
                  0 0 45px rgba(17, 17, 17, 0.2); 
    color: whitesmoke;
    text-align: center;
    padding: 10px 0;
    width: auto;
    position: relative;
    flex: 1;
}
.footer::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    border-radius: 10px; 
    background: linear-gradient(
      90deg,
      rgba(72, 68, 68, 0.8) 0%, 
      rgba(255, 255, 255, 0) 50%,  
      rgba(72, 70, 70, 0.8) 100% 
    );
    z-index: -1; 
  }

.social-media {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    flex-wrap: wrap; 
}

.social-icon {
    margin: 0 15px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.social-icon img {
    width: 30px; 
    height: 30px; 
    transition: transform 0.2s;
}

.social-icon:hover img {
    transform: scale(1.1);
}


@media (max-width: 768px) {
    .footer {
        padding: 15px 0;
    }
    
    .social-icon {
        margin: 0 10px;
    }

    .social-icon img {
        width: 24px; 
        height: 24px;
    }
}

@media (max-width: 480px) {
    .footer {
        padding: 20px 0;
    }
    
    .social-icon {
        margin: 0 8px;
    }

    .social-icon img {
        width: 20px; 
        height: 20px;
    }
}

/* .home-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  padding: 20px;
  color: white;
  font-family: 'Eros', Arial, sans-serif;
  background-color: #121212;


  background-image:
    linear-gradient(0deg, #444 1px, transparent 1px), 
    linear-gradient(90deg, #444 1px, transparent 1px), 
    radial-gradient(circle at 50% 0%, rgba(255, 255, 255, 0.3), transparent 50%),
    radial-gradient(circle at 30% 80%, rgba(0, 0, 0, 0.6), transparent 50%), 
    radial-gradient(circle at 70% 80%, rgba(0, 0, 0, 0.6), transparent 50%); 

  background-size: 40px 40px, 40px 40px, cover, cover, cover;
  background-blend-mode: overlay, overlay, normal, normal, normal;
}



.profile-section {
  max-width: 600px;
  padding: 40px;
  background-color: rgba(255, 255, 255, 0.1); 
  border-radius: 12px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

.profile-photo {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  border: 4px solid #ffffff;
  margin-bottom: 20px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3);
}

h1 {
  font-size: 2.5em;
  margin-bottom: 10px;
  color: #eaeaea;
}

p {
  font-size: 1.1em;
  line-height: 1.6;
  color: #f0f0f0;
  margin-bottom: 20px;
  padding: 0 20px;
}

.learn-more-link {
  display: inline-block;
  margin-top: 20px;
  padding: 12px 24px;
  background-color: #340cfa;
  color: white;
  text-decoration: none;
  border-radius: 8px;
  font-weight: bold;
  font-size: 1em;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
}

.learn-more-link:hover {
  background-color: #9988f9;
  transform: translateY(-2px);
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
}

.learn-more-link:focus {
  outline: 2px solid #d9534f;
}


@media (max-width: 768px) {
  
  .profile-section {
    max-width: 90%;
    padding: 30px;
  }

  .profile-photo {
    width: 140px;
    height: 140px;
    margin-bottom: 15px;
  }

  h1 {
    font-size: 2em;
    margin-bottom: 8px;
  }

  p {
    font-size: 1em;
    padding: 0 15px;
  }

  .learn-more-link {
    font-size: 0.9em;
    padding: 10px 20px;
  }
}

@media (max-width: 480px) {
 

  .profile-section {
    max-width: 100%;
    padding: 20px;
  }

  .profile-photo {
    width: 120px;
    height: 120px;
  }

  h1 {
    font-size: 1.5em;
  }

  p {
    font-size: 0.9em;
    padding: 0 10px;
  }

  .learn-more-link {
    font-size: 0.85em;
    padding: 8px 16px;
  }
}

@media (max-width: 320px) {
 

  .profile-photo {
    width: 100px;
    height: 100px;
  }

  h1 {
    font-size: 1.3em;
  }

  p {
    font-size: 0.8em;
  }

  .learn-more-link {
    font-size: 0.75em;
    padding: 6px 14px;
  }
} */
/* Home.css */


  /* General Reset */
  body {
    margin: 0;
    font-family: 'Arial', sans-serif;
    background-color: #f9f9f9;
    color: #333;
  }
  
  /* Home Page Container */
  .home-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #0c0303;
    align-items: center;
    min-height: 100vh;
    background-color: #0f0118; /* Dark Purple background */
    background-image:
      linear-gradient(0deg, rgba(4, 0, 7, 0.8) 1px, transparent 1px),  /* Dark Purple grid lines with opacity */
      linear-gradient(90deg, rgba(31, 3, 50, 0.8) 1px, transparent 1px),  /* Dark Purple grid lines with opacity */
      radial-gradient(circle at 50% 0%, rgba(9, 0, 6, 0.3), transparent 50%),  /* Dark shine at the top center */
      radial-gradient(circle at 30% 80%, rgba(20, 1, 15, 0.6), transparent 50%),  /* Dark shine effect */
      radial-gradient(circle at 70% 80%, rgba(26, 1, 17, 0.6), transparent 50%);  /* Dark shine effect */
    background-size: 40px 40px, 40px 40px, cover, cover, cover;
    
    color: #fff;
    padding: 2rem;
  }
  
  /* Hero Section */
  .hero-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .hero-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
  }
  
  .profile-photo-wrapper {
    flex: 1;
    text-align: center;
    margin: 1rem;
  }
  
  .profile-photo {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    border: 5px solid #fff;
    object-fit: cover;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    position:absolute;
    top:280px;
    left:200px;
  }
  
  .hero-text {
    flex: 2;
    margin: 1rem;
   
  }
  
  .hero-text h1 {
    font-size: 3rem;
    margin-bottom: 0.5rem;
  }
  
  .hero-text h1 .highlight {
    color: #e3d6d8;
  }
  
  .hero-text h2 {
    font-size: 2rem;
    font-weight: 300;
    margin-bottom: 1rem;
  }
  
  .hero-text p {
    font-size: 1.2rem;
    line-height: 1.8;
    margin-bottom: 2rem;
  }
  
  /* Action Buttons */
  .action-buttons {
    display: flex;
    gap: 1rem;
    justify-content: center;
  }
  
  .btn-primary {
    background-color: #070606;
    color: #efeff0;
    padding: 0.8rem 1.5rem;
    text-decoration: none;
    border-radius: 50px;
    font-weight: bold;
    font-size: 1rem;
    transition: all 0.3s ease-in-out;
  }
  
  .btn-primary:hover {
    background-color: #4441ff;
    color: #fff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .btn-secondary {
    background-color: transparent;
    color: #fff;
    padding: 0.8rem 1.5rem;
    text-decoration: none;
    border: 2px solid #fff;
    border-radius: 50px;
    font-weight: bold;
    font-size: 1rem;
    transition: all 0.3s ease-in-out;
  }
  
  .btn-secondary:hover {
    background-color: #fff;
    color: #3f08e2;
  }
  
 /* General Styles for Mobile */
@media (max-width: 1024px) {
  .hero-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .profile-photo {
    width: 200px;
    height: 200px;
    position: static;
    margin-bottom: 20px;
  }

  .hero-text h1 {
    font-size: 2.5rem;
  }

  .hero-text h2 {
    font-size: 2rem;
  }

  .hero-text p {
    font-size: 1.2rem;
  }
}

@media (max-width: 768px) {
  .profile-photo {
    width: 280px;
    height: 280px;
  }

  .hero-text h1 {
    font-size: 2rem;
  }

  .hero-text h2 {
    font-size: 1.5rem;
  }

  .hero-text p {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .profile-photo {
    width: 250px;
    height: 250px;
  }

  .hero-text h1 {
    font-size: 1.8rem;
  }

  .hero-text h2 {
    font-size: 1.4rem;
  }

  .hero-text p {
    font-size: 0.9rem;
  }
}

@media (max-width: 320px) {
  .profile-photo {
    width: 200px;
    height: 200px;
  }

  .hero-text h1 {
    font-size: 1.5rem;
  }

  .hero-text h2 {
    font-size: 1.2rem;
  }

  .hero-text p {
    font-size: 0.8rem;
  }
}

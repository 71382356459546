.header {
  position: fixed;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  width: auto;
  height: 40px;
  background-color: black; /* Set background color to black */
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  border-radius: 8px;
}

.nav-links {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 20px;
}

.nav-link {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  transition: color 0.3s;
}

.nav-link i {
  margin-right: 8px;
  font-size: 18px;
}

.nav-link:hover {
  color: #2f07fa;
}

.dropdown-container {
  display: none; 
}

#menuButton {
  font-size: 24px;
  background-color: #0a0303;
  color: white;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.dropdown-content {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  box-shadow: 0px 8px 16px rgba(8, 0, 0, 0.2);
  border-radius: 5px;
  min-width: 160px;
  z-index: 1;
}

.link {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: 16px;
}

.link:hover {
  background-color: #ddd;
}

@media (max-width: 768px) {
  .header {
    display: none;
    background-color: Black;
    
  }

  .dropdown-container {
    display: inline-block;
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 1000;
  }

  #menuButton {
    background-color: #222;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .dropdown-content {
    background-color: #0a0101;
    border-radius: 5px;
    padding: 10px;
    position: absolute;
    top: 50px;
    right: 0;
    z-index: 1001;
    width: 200px;
  }

  .dropdown-content .link {
    display: block;
    padding: 8px;
    color: white;
    text-decoration: none;
  }

  .dropdown-content .link:hover {
    background-color: #ff4500;
  }
}

.volunteering-page {
 
  padding: 20px;
  text-align: center;
  color: rgb(219, 219, 229);
  font-family: Arial, sans-serif;
  background-color: #0f0118; /* Dark Purple background */
  background-image:
    linear-gradient(0deg, rgba(4, 0, 7, 0.8) 1px, transparent 1px),  /* Dark Purple grid lines with opacity */
    linear-gradient(90deg, rgba(31, 3, 50, 0.8) 1px, transparent 1px),  /* Dark Purple grid lines with opacity */
    radial-gradient(circle at 50% 0%, rgba(9, 0, 6, 0.3), transparent 50%),  /* Dark shine at the top center */
    radial-gradient(circle at 30% 80%, rgba(20, 1, 15, 0.6), transparent 50%),  /* Dark shine effect */
    radial-gradient(circle at 70% 80%, rgba(26, 1, 17, 0.6), transparent 50%);  /* Dark shine effect */
  background-size: 40px 40px, 40px 40px, cover, cover, cover;
  background-blend-mode: overlay, overlay, normal, normal, normal;
 
}

.volunteering-page h1 {
  font-size: 2.5em;
  color:rgb(236, 236, 239); 
  margin-bottom: 20px;
  

}



.volunteering-content {
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 800px;
  margin: 0 auto;
}

.gallery-block,
.description-block {
  background: #f7f2f2; 
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.gallery-block h2,
.description-block h2 {
  color: #3c4e76;
  font-size: 1.8em;
  margin-bottom: 15px;
}

.gallery-container {
  overflow: hidden;
  position: relative;
  border-radius: 8px;
  margin-top: 10px;
  height: 150px; 
}

.image-scroll {
  display: flex;
  animation: scroll 20s linear infinite;
}

.gallery-image {
  width: 200px;
  height: 150px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}


@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-50% / 2));
  }
}


.image-scroll:hover {
  animation-play-state: paused;
}

.description-block p {
  font-size: 1em;
  color: #555;
  line-height: 1.6;
  margin-bottom: 10px;
  text-align: justify
}


.volunteering-page {
  padding: 20px;
  text-align: center;
  color: rgb(219, 219, 229);
  font-family: Arial, sans-serif;
  background-color: #0f0118; /* Dark Purple background */
  background-image:
    linear-gradient(0deg, rgba(4, 0, 7, 0.8) 1px, transparent 1px),  /* Dark Purple grid lines with opacity */
    linear-gradient(90deg, rgba(31, 3, 50, 0.8) 1px, transparent 1px),  /* Dark Purple grid lines with opacity */
    radial-gradient(circle at 50% 0%, rgba(9, 0, 6, 0.3), transparent 50%),  /* Dark shine at the top center */
    radial-gradient(circle at 30% 80%, rgba(20, 1, 15, 0.6), transparent 50%),  /* Dark shine effect */
    radial-gradient(circle at 70% 80%, rgba(26, 1, 17, 0.6), transparent 50%);  /* Dark shine effect */
  background-size: 40px 40px, 40px 40px, cover, cover, cover;
  background-blend-mode: overlay, overlay, normal, normal, normal;
}

.volunteering-page h1 {
  font-size: 2.5em;
  color: rgb(236, 236, 239);
  margin-bottom: 20px;
}

.header {
  width: 100%;
  background: rgb(11, 0, 0);
  border-radius: 15px;
  overflow: hidden;
 
  padding: 10px;
  position: relative;
  transition: transform 0.3s;
}

.volunteering-content {
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 800px;
  margin: 0 auto;
}

.gallery-block,
.description-block {
  background-color: #0a0000;
  
  border-radius: 10px;
  padding: 20px;
  
}

.gallery-block h2,
.description-block h2 {
  color: #dcdfe6;
  font-size: 1.8em;
  margin-bottom: 15px;
}

.gallery-container {
  overflow: hidden;
  position: relative;
  border-radius: 8px;
  margin-top: 10px;
  height: 150px;
}

.image-scroll {
  display: flex;
  animation: scroll 20s linear infinite;
}

.gallery-image {
  width: 200px;
  height: 150px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(11, 3, 3, 0.2);
  
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-50% ));
  }
}

.image-scroll:hover {
  animation-play-state: paused;
}

.description-block p {
  font-size: 1em;
  color: #f8f5f5;
  line-height: 1.6;
  margin-bottom: 10px;
  text-align: justify;
}


@media (max-width: 768px) {
  
  
  

  .volunteering-page h1 {
    font-size: 2em;
  }

  .volunteering-content {
    gap: 30px;
    padding: 15px;
  }

  .gallery-block h2,
  .description-block h2 {
    font-size: 1.6em;
  }

  .gallery-image {
    width: 150px;
    height: 120px;
  }

  .description-block p {
    font-size: 0.95em;
  }
}



